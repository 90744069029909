import React, { startTransition } from 'react';
import axios from 'axios';
import { useDebouncedCallback } from 'use-debounce';
import lottie from 'lottie-web';
import { getApp } from 'firebase/app';
import { collection, doc, getDoc, getDocs, getFirestore } from '@firebase/firestore';

import useScriptRef from 'hooks/useScriptRef';
import { useAuthContext } from 'contexts/AuthContext';
import { FlexBox } from 'components/flex-box';
import likeLoadingJson from 'assets/lottie/like-loader.json';

import { Box, BoxProps, IconButton, IconButtonProps, Typography } from '@mui/material';
// import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
// import FavoriteBorderSharpIcon from '@mui/icons-material/FavoriteBorderSharp';

type Props = {
  variant: 'none' | 'summary' | 'text';
  pdataId: string;
  reviewId: string;
  wrapperProps?: BoxProps;
  iconButtonProps?: IconButtonProps;
};

/**
 * 리뷰 좋아요 버튼
 */
const ReviewLikeButton: React.FC<Props> = ({
  variant = 'summary',
  pdataId,
  reviewId,
  wrapperProps,
  iconButtonProps
}: Props) => {
  const scriptedRef = useScriptRef();
  // 클릭시 로티 애니메이션
  const lottieContainer = React.useRef<HTMLDivElement | null>(null);
  // 로티 플레이어
  const [lottiePlayer, setLottiePlayer] = React.useState<any>(null);

  // 초기화 완료여부
  const [initialized, setInitialized] = React.useState<boolean>(false);
  // 전체 좋아요 수
  const [totalLikeCount, setTotalLikeCount] = React.useState<number>(0);
  // 나의 좋아요 여부
  const [myLike, setMyLike] = React.useState<boolean>(false);

  // 로그인 사용자 정보
  const { state, openSignInModal } = useAuthContext();

  // 나의 리뷰 좋아요 정보 불러오기
  const fetchMyReviewLike = React.useCallback(async (pdataId: string, reviewId: string, uid: string) => {
    React.startTransition(() => {
      setMyLike(false);
      setInitialized(false);
      setTotalLikeCount(0);
    }); //

    const firestore = getFirestore(getApp());

    // 전체 좋아요 수
    const reviewLikeSnaps = await getDocs(collection(firestore, `pdata/${pdataId}/reviews/${reviewId}/review_like`));
    setTotalLikeCount(reviewLikeSnaps.empty ? 0 : reviewLikeSnaps.size);

    // 로그인한 사용자
    if (uid) {
      // 해당 스냅샷이 존재하면 좋아요를 누른 것
      const snap = await getDoc(doc(firestore, `pdata/${pdataId}/reviews/${reviewId}/review_like/${uid}`));

      if (!scriptedRef.current) {
        return console.warn('[ReviewLikeButton] Unmounted component.');
      }

      React.startTransition(() => {
        setMyLike(snap.exists());
        setInitialized(true);
      });
    }
    // 로그인하지 않은 사용자
    else {
      React.startTransition(() => {
        setMyLike(false);
        setInitialized(true);
      });
    }
  }, []);

  // 나의 리뷰 좋아요 여부 불러오기
  React.useLayoutEffect(() => {
    // console.log('------------------> fetchMyReviewLike', pdataId, reviewId);
    fetchMyReviewLike(pdataId, reviewId, state.user?.uid);
  }, [pdataId, reviewId, state.user?.uid]);

  const toggleReviewLike = useDebouncedCallback(async (pdataId: string, reviewId: string) => {
    // 로그인한 사용자의 경우
    if (state.isLoggedIn) {
      // console.log('#### 좋아요 토글', before, pdataId, reviewId, state.user?.uid);
      const result = await axios
        .post(`/api/pdata/${pdataId}/review/like`, { pdata_id: pdataId, review_id: reviewId })
        .then((response) => response.data)
        .catch((error) => {
          console.error('리뷰 좋아요 토글 에러: ', error);
          return { success: false };
        });

      if (!scriptedRef.current) {
        return console.warn('[ReviewLikeButton] Unmounted component.');
      }
      lottiePlayer?.stop();

      // console.log('# 리뷰 좋아요 토글결과: ', result);
      if (result.success) {
        startTransition(() => {
          setMyLike(result.my_like);
          setTotalLikeCount(result.total_count);
        });
      }
    }
    // 비로그인 사용자
    else {
      // 로그인 창 띄우기
      openSignInModal();
    }
  }, 300);

  const activateLottiPlayer = React.useCallback((container: HTMLDivElement) => {
    // console.log('### activeLottiPlayer');
    const player = lottie.loadAnimation<'svg'>({
      container,
      autoplay: false,
      loop: true,
      animationData: likeLoadingJson
      // rendererSettings: {
      //   hideOnTransparent: true
      // }
    });
    // console.log('---> player: ', player);
    setLottiePlayer(player);
  }, []);

  React.useEffect(() => {
    activateLottiPlayer(lottieContainer.current!);
  }, [reviewId]);

  const likeCountStr = React.useMemo(() => {
    if (!initialized) return ' ';
    return totalLikeCount.toString(); // todo 1000이상 1.0K|M|B 표시
  }, [totalLikeCount, initialized]);

  // render
  return (
    <FlexBox
      className="review-btn-wrapper"
      alignItems="center"
      display="inline-flex"
      title={initialized ? `${totalLikeCount.toLocaleString()}명이 이 리뷰를 좋아합니다.` : ''}
      pr={0.5}
      mr={0.5}
      {...wrapperProps}
    >
      <IconButton
        key={`pdata-${pdataId}-review-${reviewId}-like-button`}
        size="small"
        disabled={!initialized}
        sx={{ position: 'relative', visibility: initialized ? 'visible' : 'hidden' }}
        color={myLike ? 'info' : 'default'}
        onClick={(e) => {
          if (!e.isTrusted) return;

          if (state.isLoggedIn) {
            startTransition(() => {
              setMyLike((prev) => !prev);
            });
            // console.log('#lottiePlayer: ', lottiePlayer);
            lottiePlayer?.play();
            toggleReviewLike(pdataId, reviewId);
          } else {
            openSignInModal();
            // window.alert('로그인 후 이용해주세요.');
          }
        }}
        {...iconButtonProps}
      >
        <Box
          ref={lottieContainer}
          width="100%"
          height="100%"
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
        />
        {myLike ? <ThumbUpIcon fontSize="inherit" /> : <ThumbUpOutlinedIcon fontSize="inherit" />}
      </IconButton>

      {initialized && (
        <>
          {variant === 'summary' && (
            <Typography component="b" variant="caption">
              {likeCountStr}
            </Typography>
          )}
          {variant === 'text' && (
            <Typography variant="caption" color="rgba(20, 2, 41, 0.7)">
              <b>{likeCountStr}명</b>이 이 리뷰를 좋아합니다.
            </Typography>
          )}
        </>
      )}
    </FlexBox>
  );
};

export default ReviewLikeButton;

/**
 * 리뷰 쓰레드 하트 아이콘
 */
